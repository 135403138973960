// Core variables and mixins

.dashboard-stat .visual {
  width: 80px;
  height: 92px;
  display: block;
  float: left;
  padding-top: 0px;
  padding-left: 53px;
  margin-bottom: 17px;
  font-size: 35px;
  line-height: 35px;
}

.selections {
  float:right;
  margin: 10px 0;
}
.modal-header {
  text-align: center;
  font-weight: 400;
  font-size: larger;
}
.modal-help-launcher {
  display: block;
  text-align: right;
  width: 97%;
  margin: -15px 0 0 0;
  font-size: large;
  color: #3598dc;
  cursor: pointer;
}

.selections-counter-container{
  display: inline-block;
  padding: 0 10px;
}

.selections-actions-container{
  text-align: right;
  display: inline-block;
}
tr.gradeX.active:hover,
tr.gradeX.active:hover > td{
  background-color: #32d29a !important;
}

tr.gradeX.active,
tr.gradeX.active > td {
  background-color: #1bbc49 !important;
}

.lock-items label {
  display:block;
}
.lock-items {
  padding: 10px 0;
}

.lock-list select{
  width:200px;
  display: inline-block;
  text-align: center;
}

.lock-list,
.lock-add,
.lock-bypass {
  text-align: center;
  margin: 0 0 20px 0;
}

ul.referredProducts {
  list-style: none;
}

.btn-success, .confirmation-buttons > .btn-group > .btn-primary {
  color: #fff !important;
  background-color: #36c6d3 !important;
  border-color: #2bb8c4 !important;
}

.btn-danger, .confirmation-buttons > .btn-group > .btn-secondary {
  color: #fff;
  background-color: #ed6b75;
  border-color: #ea5460;
}